/* eslint-disable no-unused-vars */
export enum RoutePath {
  ALL = '/*',
  LOGIN = '/',
  RESET_PASSWORD = '/reset-password',
  NOT_FOUND = '/not-found',
  USER = '/user',
  DETAIL_USED_CAR_MARKET_INFORMATION = '/sample/used-car-market-information/detail/:id',
  DETAIL_AUCTION_INFORMATION = '/sample/auction-information/detail/:id',
}
