import { Paper, InputBase, Button } from '@mui/material';
import * as React from 'react';
import { useForm, Controller } from 'react-hook-form';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import { TITLE } from '../../../constants/title';
import { TypeAction } from '../index';
import { validate } from '../../../constants/validate';
import { regex } from '../../../constants/regex';
import { styled } from '@mui/material/styles';

export const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 3
};

const StyledModal = styled(Modal)(`
  .MuiPaper-root {
    box-shadow: none
  }
`);

export default function AccountCUD(props) {
  const { typeAction, detailAccount, setOpen, setTypeAction, open } = props;

  const {
    handleSubmit,
    setValue,
    control,
    clearErrors,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: {
      name: '',
      email: '',
      password: ''
    }
  });

  const TitleModal = typeAction?.includes(TypeAction.CREATE)
    ? TITLE.CREATE
    : typeAction?.includes(TypeAction.EDIT)
      ? TITLE.EDIT
      : TITLE.DETAIL;

  const isDisabled = typeAction?.includes(TypeAction.DETAIL);

  const isCreate = typeAction?.includes(TypeAction.CREATE);

  const isEdit = typeAction?.includes(TypeAction.EDIT);

  const onSubmit = (data) => {
    const dataCustom = { name: data?.name?.trim(), email: data?.email?.trim() };
  };

  const handleClose = () => {
    setOpen(false);
    setTypeAction('');
    clearErrors();
    reset();
  };

  React.useEffect(() => {
    if (!isCreate) {
      setValue('name', detailAccount?.name);
      setValue('email', detailAccount?.email);
    } else {
      reset();
    }
  }, [detailAccount, typeAction, isCreate]);
  return (
    <StyledModal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      width={500}
    >
      <Box sx={style}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              background: '#2d67f6',
              margin: 0,
              color: 'white',
              borderTopLeftRadius: '12px',
              borderTopRightRadius: '12px',
              p: 2,
              fontSize: '16px'
            }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <span>{TitleModal}</span>
              <CloseIcon sx={{ cursor: 'pointer' }} onClick={handleClose} />
            </div>
          </Box>
          <Box
            id="modal-modal-description"
            sx={{
              my: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              p: 2,
              width: '100%'
            }}
          >
            <div style={{ width: '100%' }}>
              <Paper sx={{ mb: 1, fontSize: '14px', fontWeight: 700 }}>
                {!isDisabled && <span style={{ color: 'red' }}>*</span>} 名前
              </Paper>
              <Paper
                component="form"
                sx={{
                  p: '2px 4px',
                  display: 'flex',
                  alignItems: 'center',
                  border: '1px solid rgba(0, 0, 0, 0.15)',
                  borderRadius: '2px',
                  backgroundColor: isDisabled && 'rgba(0, 0, 0, 0.04)',
                  cursor: isDisabled && 'no-drop'
                }}
              >
                <Controller
                  control={control}
                  rules={{
                    required: `名前${validate.validate_input}`,
                    maxLength: {
                      value: 50,
                      message: `名前${validate.exceed_50_numbers}`
                    }
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <InputBase
                      sx={{
                        ml: 1,
                        flex: 1
                      }}
                      disabled={isDisabled}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                  name="name"
                />
              </Paper>
            </div>
            {errors.name && (
              <span
                style={{
                  textAlign: 'left',
                  width: '100%',
                  color: 'red',
                  paddingTop: '3px'
                }}
              >
                {errors?.name?.message}
              </span>
            )}

            <div style={{ width: '100%' }}>
              <Paper sx={{ mb: 1, fontSize: '14px', fontWeight: 700, mt: 2 }}>
                {!isDisabled && <span style={{ color: 'red' }}>*</span>}{' '}
                メールアドレス
              </Paper>
              <Paper
                component="form"
                sx={{
                  p: '2px 4px',
                  display: 'flex',
                  alignItems: 'center',
                  border: '1px solid rgba(0, 0, 0, 0.15)',
                  borderRadius: '2px',
                  backgroundColor: isDisabled && 'rgba(0, 0, 0, 0.04)',
                  cursor: isDisabled && 'no-drop'
                }}
              >
                <Controller
                  control={control}
                  rules={{
                    required: `メールアドレス${validate.validate_input}`,
                    validate: (value) => {
                      if (!regex.REGEX_EMAIL.test(value)) {
                        return validate.invalid_email;
                      }
                      return true;
                    }
                  }}
                  name="email"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <InputBase
                      sx={{
                        ml: 1,
                        flex: 1
                      }}
                      disabled={isDisabled}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </Paper>
            </div>
            {errors.email && (
              <span
                style={{
                  textAlign: 'left',
                  width: '100%',
                  color: 'red',
                  paddingTop: '3px'
                }}
              >
                {errors?.email?.message}
              </span>
            )}
            <div style={{ width: '100%' }}>
              <Paper sx={{ mb: 1, fontSize: '14px', fontWeight: 700, mt: 3 }}>
                {!isDisabled && !isEdit && (
                  <span style={{ color: 'red' }}>*</span>
                )}{' '}
                パスワード
              </Paper>
              <Paper
                component="form"
                sx={{
                  p: '2px 4px',
                  display: 'flex',
                  alignItems: 'center',
                  border: '1px solid rgba(0, 0, 0, 0.15)',
                  borderRadius: '2px',
                  backgroundColor: isDisabled && 'rgba(0, 0, 0, 0.04)',
                  cursor: isDisabled && 'no-drop'
                }}
              >
                <Controller
                  control={control}
                  rules={{
                    required: {
                      value: isCreate,
                      message: `パスワード${validate.validate_input}`
                    },
                    validate: (value) => {
                      if (
                        ((value?.trim().length < 8 ||
                          !regex.REGEX_PW.test(value)) &&
                          isCreate) ||
                        (!isCreate &&
                          ((value.trim().length < 8 &&
                            value.trim().length > 0) ||
                            (value.trim().length >= 8 &&
                              !regex.REGEX_PW.test(value))))
                      ) {
                        return `パスワード${validate.validate_password}`;
                      }
                      return true;
                    }
                  }}
                  name="password"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <InputBase
                      sx={{
                        ml: 1,
                        flex: 1
                      }}
                      disabled={isDisabled}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      type="password"
                    />
                  )}
                />
              </Paper>
            </div>
            {errors.password && (
              <span
                style={{
                  textAlign: 'left',
                  width: '100%',
                  color: 'red',
                  paddingTop: '3px'
                }}
              >
                {errors?.password?.message}
              </span>
            )}
            <Paper sx={{ display: 'flex', width: '100%', gap: 2, mt: 5 }}>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  width: '100%',
                  height: 48,
                  borderRadius: '2px',
                  fontWeight: 400,
                  background: '#ffffff',
                  color: '#000000',
                  border: '1px solid #d9d9d9',
                  '&:hover': {
                    background: 'transparent'
                  },
                  boxShadow: 'none'
                }}
                onClick={handleClose}
              >
                キャンセル
              </Button>
              {!isDisabled && (
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    width: '100%',
                    height: 48,
                    borderRadius: '2px',
                    fontWeight: 400,
                    background: '#2D67F6',
                    '&:hover': {
                      background: '#2D67F6'
                    },
                    boxShadow: 'none'
                  }}
                >
                  作成
                </Button>
              )}
            </Paper>
          </Box>
        </form>
      </Box>
    </StyledModal>
  );
}
