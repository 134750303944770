import {
  FormControl,
  MenuItem,
  OutlinedInput,
  Pagination,
  Paper,
  styled,
  useTheme
} from '@mui/material';
import { StyledSelect } from 'src/sections/accountManagement';
const StyledPagination = styled(Pagination)(`
    .MuiPaginationItem-root {
      border-radius: 2px;  
      font-size: 14px;
      font-weight: 500;
    }
    .Mui-selected{
      background: #ffffff !important;  
      border: 1px solid #1890FF;
      color: #1890FF;
      font-size: 14px;
      font-weight: 500;
    }
  `);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const optionPages = ['10/ページ', '20/ページ', '50/ページ', '100/ページ'];

export default function PaginationCustom(props) {
  const {
    currentPagePagination,
    rowsPerPage,
    setPage,
    currentCount,
    setOptionPage
  } = props;

  const theme = useTheme();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChange = (event) => {
    const {
      target: { value }
    } = event;
    setOptionPage(typeof value === 'string' ? value.split(',') : value);
    setPage(0);
  };

  function getStyles(name, personName, theme) {
    return {
      fontWeight: personName.includes(name)
        ? theme.typography.fontWeightMedium
        : theme.typography.fontWeightRegular
    };
  }
  return (
    <>
      <Paper
        sx={{
          marginTop: 2,
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
          gap: 1
        }}
      >
        <StyledPagination
          page={currentPagePagination}
          onChange={handleChangePage}
          count={currentCount}
          variant="outlined"
          shape="rounded"
        />
        <FormControl sx={{ width: 104, height: 32 }}>
          <StyledSelect
            displayEmpty
            value={rowsPerPage}
            onChange={handleChange}
            input={<OutlinedInput />}
            renderValue={(selected) => {
              return selected.join(', ');
            }}
            MenuProps={MenuProps}
            inputProps={{ 'aria-label': 'Without label' }}
            sx={{ height: '32px' }}
          >
            {optionPages.map((name) => (
              <MenuItem
                key={name}
                value={name}
                style={getStyles(name, rowsPerPage, theme)}
              >
                {name}
              </MenuItem>
            ))}
          </StyledSelect>
        </FormControl>
      </Paper>
    </>
  );
}
